var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approval"},[_c('div',[_vm._m(0),_c('div',{staticStyle:{"padding":"10px 20px 20px 20px","box-shadow":"0px 3px 3px 1px #e7e7e7","position":"relative","z-index":"10"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',[_vm._v("客户名称:")]),_c('el-input',{staticStyle:{"width":"20%","margin":"0 10px"},attrs:{"placeholder":"请输入客户名称"},model:{value:(_vm.ruleForm.clientName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "clientName", $$v)},expression:"ruleForm.clientName"}}),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function () {
					_vm.totalRows = 0;

					_vm.ruleForm.pageNo = 1;
					_vm.getSchemeApprovalInfoList(true);
				}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function () {
					_vm.totalRows = 0;

					_vm.ruleForm.clientName = '';
					_vm.ruleForm.pageNo = 1;
					_vm.getSchemeApprovalInfoList(true);
				}}},[_vm._v("清空")])],1)],1)])]),(_vm.isempty)?_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.load),expression:"load"}],staticClass:"test-1",staticStyle:{"overflow":"auto","height":"calc(100vh - 260px)","padding":"0 0 10px 0"},attrs:{"infinite-scroll-disabled":"disabled"}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticStyle:{"padding":"10px 10px"}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"desc",class:{
					activeColor:
						item.schemeApprovalStatus != 4 &&
						item.schemeApprovalStatus != 6 &&
						item.schemeApprovalStatus != 7 &&
						item.schemeApprovalStatus != 8,
				}},[_vm._v(" "+_vm._s((item.schemeApprovalStatus == 4 || item.schemeApprovalStatus == 6 || item.schemeApprovalStatus == 7 || item.schemeApprovalStatus == 8) ? '已完结' : '进行中')+" ")]),_c('p',{staticClass:"content-title",staticStyle:{"font-size":"22px","font-weight":"800","color":"#23924f","cursor":"pointer","display":"flex","align-items":"center"},on:{"click":function($event){return _vm.showDialogVisible(item)}}},[_vm._v(" "+_vm._s(item.clientName)+" "),_c('span',{staticStyle:{"color":"red","font-weight":"400","font-size":"15px"}},[_vm._v("("+_vm._s(item.schemeApprovalTypeName)+")")])]),_c('div',{staticStyle:{"padding":"10px 10px"}},[_c('div',{staticClass:"content-box"},[_c('div',[_c('p',[_vm._v("产品名称："+_vm._s(item.financialProductName))]),_c('p',[_vm._v("批复时间："+_vm._s(item.approvalTime))]),_c('p',[_vm._v("批复金额(万元)："+_vm._s(item.approvalAmount))]),_c('p',[_vm._v("批复利率/费率(%)："+_vm._s(item.approvalRate))]),_c('p',[_vm._v(" 贷款担保条件："+_vm._s(item.loanGuaranteeConditions)+" ")]),_c('p',[_vm._v(" 贷款前提条件："+_vm._s(item.loanPrerequisites)+" ")]),_c('p',[_vm._v(" 其他配合要求："+_vm._s(item.otherRequirements)+" ")])]),_c('div',[_c('p',[_vm._v("融资期限(月)："+_vm._s(item.loanDeadline))]),_c('p',[_vm._v(" 批复有效期(月)："+_vm._s(item.approvalValidityTerm)+" ")]),_c('p',[_vm._v("每月还款日："+_vm._s(item.repaymentDate))]),_c('p',[_vm._v("还款方式："+_vm._s(item.repaymentMethod))])])])]),_c('div',{staticStyle:{"position":"relative"}},[_c('myStateItem',{attrs:{"item":item,"stateList":_vm.stateList}}),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[(item.schemeApprovalStatus != 5 &&
					item.schemeApprovalStatus != 6 &&
					item.schemeApprovalStatus != 7 &&
					item.currentApprovalLink != 7
					)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.editData(item, 'stateVisible')}}},[_vm._v("批复环节落实 ")]):_vm._e(),(item.schemeApprovalStatus != 4 &&
					item.schemeApprovalStatus != 6 &&
					item.schemeApprovalStatus != 7 &&
					item.schemeApprovalStatus != 8
					)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.editData(item, 'editVisible')}}},[_vm._v("修改 ")]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.feedback(item)}}},[_vm._v("反馈进度及意见 ")])],1)],1)])],1)}),(_vm.list.length)?_c('div',[(_vm.loading)?_c('p',{staticStyle:{"text-align":"center","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(" 加载中 ")]):_vm._e(),(_vm.noMore)?_c('p',{staticStyle:{"text-align":"center","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(" 已加载全部数据 ")]):_vm._e()]):_vm._e()],2):_c('div',{staticStyle:{"padding-top":"65px"}},[_c('el-empty',{attrs:{"description":"没有找到符合条件的数据"}})],1),_c('publicReply',{attrs:{"paramsId":_vm.params,"replyVisible":_vm.replyVisible},on:{"update:replyVisible":function($event){_vm.replyVisible=$event},"update:reply-visible":function($event){_vm.replyVisible=$event}}}),_c('publicInfoDrawer',{attrs:{"privacyType":_vm.privacyType,"allData":_vm.allData,"dialogVisible":_vm.publicDialogVisible,"paramsId":_vm.paramsId},on:{"update:dialogVisible":function($event){_vm.publicDialogVisible=$event},"update:dialog-visible":function($event){_vm.publicDialogVisible=$event},"closeVisible":function($event){_vm.publicDialogVisible = false}}}),_c('editDrawer',{attrs:{"params":_vm.params,"editVisible":_vm.editVisible},on:{"successVisible":_vm.editSuccess,"closeVisible":function($event){_vm.editVisible = false},"update:editVisible":function($event){_vm.editVisible=$event},"update:edit-visible":function($event){_vm.editVisible=$event}}}),_c('stateChange',{attrs:{"stateList":_vm.stateList,"params":_vm.params,"stateVisible":_vm.stateVisible},on:{"closeVisible":function($event){_vm.stateVisible = false},"update:stateVisible":function($event){_vm.stateVisible=$event},"update:state-visible":function($event){_vm.stateVisible=$event},"success":_vm.success}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-title"},[_c('p',[_vm._v("客户的批复")])])}]

export { render, staticRenderFns }