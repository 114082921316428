<template>
	<div class="approval">
		<div>
			<div class="my-title">
				<p>客户的批复</p>
			</div>
			<div style="
					padding: 10px 20px 20px 20px;
					box-shadow: 0px 3px 3px 1px #e7e7e7;
					position: relative;
					z-index: 10;
				">
				<div style="display: flex; align-items: center">
					<span>客户名称:</span>
					<el-input style="width: 20%; margin: 0 10px" placeholder="请输入客户名称" v-model="ruleForm.clientName"></el-input>
					<div>
						<el-button type="primary" @click="() => {
						totalRows = 0;

						ruleForm.pageNo = 1;
						getSchemeApprovalInfoList(true);
					}
						">查询</el-button>

						<el-button type="primary" @click="() => {
						totalRows = 0;

						ruleForm.clientName = '';
						ruleForm.pageNo = 1;
						getSchemeApprovalInfoList(true);
					}
						">清空</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="test-1" v-if="isempty" v-infinite-scroll="load" infinite-scroll-disabled="disabled" style="
				overflow: auto;
				height: calc(100vh - 260px);
				padding: 0 0 10px 0;
			">
			<div style="padding: 10px 10px" v-for="(item, index) in list" :key="index">
				<el-card class="box-card">
					<div class="desc" :class="{
						activeColor:
							item.schemeApprovalStatus != 4 &&
							item.schemeApprovalStatus != 6 &&
							item.schemeApprovalStatus != 7 &&
							item.schemeApprovalStatus != 8,
					}">
						{{
						(item.schemeApprovalStatus == 4 ||
							item.schemeApprovalStatus == 6 ||
							item.schemeApprovalStatus == 7 ||
							item.schemeApprovalStatus == 8)
							? '已完结'
							: '进行中'
					}}
					</div>
					<p @click="showDialogVisible(item)" class="content-title" style="
							font-size: 22px;
							font-weight: 800;
							color: #23924f;
							cursor: pointer;
							display: flex;
							align-items: center;
						">
						{{ item.clientName }}
						<span style="
								color: red;
								font-weight: 400;
								font-size: 15px;
							">({{ item.schemeApprovalTypeName }})</span>
					</p>
					<div style="padding: 10px 10px">
						<div class="content-box">
							<div>
								<p>产品名称：{{ item.financialProductName }}</p>
								<p>批复时间：{{ item.approvalTime }}</p>
								<p>批复金额(万元)：{{ item.approvalAmount }}</p>
								<p>批复利率/费率(%)：{{ item.approvalRate }}</p>

								<p>
									贷款担保条件：{{
						item.loanGuaranteeConditions
					}}
								</p>
								<p>
									贷款前提条件：{{ item.loanPrerequisites }}
								</p>
								<p>
									其他配合要求：{{ item.otherRequirements }}
								</p>
							</div>
							<div>
								<p>融资期限(月)：{{ item.loanDeadline }}</p>
								<p>
									批复有效期(月)：{{
						item.approvalValidityTerm
					}}
								</p>
								<p>每月还款日：{{ item.repaymentDate }}</p>
								<p>还款方式：{{ item.repaymentMethod }}</p>
							</div>
						</div>
					</div>
					<div style="position: relative">
						<myStateItem :item="item" :stateList="stateList" />
						<div style="display: flex; justify-content: flex-end">
							<el-button type="primary" @click="editData(item, 'stateVisible')" v-if="item.schemeApprovalStatus != 5 &&
						item.schemeApprovalStatus != 6 &&
						item.schemeApprovalStatus != 7 &&
						item.currentApprovalLink != 7
						">批复环节落实
							</el-button>
							<el-button type="primary" @click="editData(item, 'editVisible')" v-if="item.schemeApprovalStatus != 4 &&
						item.schemeApprovalStatus != 6 &&
						item.schemeApprovalStatus != 7 &&
						item.schemeApprovalStatus != 8
						">修改
							</el-button>
							<el-button type="primary" @click="feedback(item)">反馈进度及意见
							</el-button>
						</div>
					</div>
				</el-card>
			</div>
			<div v-if="list.length">
				<p style="text-align: center; color: rgba(0, 0, 0, 0.45)" v-if="loading">
					加载中
				</p>
				<p style="text-align: center; color: rgba(0, 0, 0, 0.45)" v-if="noMore">
					已加载全部数据
				</p>
			</div>
		</div>
		<div style="padding-top: 65px" v-else>
			<el-empty description="没有找到符合条件的数据"></el-empty>
		</div>
		<publicReply :paramsId="params" :replyVisible.sync="replyVisible" />
		<publicInfoDrawer :privacyType="privacyType" :allData="allData" :dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId" @closeVisible="publicDialogVisible = false" />
		<editDrawer @successVisible="editSuccess" @closeVisible="editVisible = false" :params="params"
			:editVisible.sync="editVisible" />
		<stateChange :stateList="stateList" @closeVisible="stateVisible = false" :params="params"
			:stateVisible.sync="stateVisible" @success="success" />
	</div>
</template>

<script>
import { myMixin } from '@/mixins';

import { schemeApprovalInfoList, itemByType } from '@/api/index.js';
import myStateItem from './module/stateItem.vue';
import editDrawer from './module/editDrawer.vue';
import stateChange from './module/stateChange.vue';
export default {
	name: 'approval',
	mixins: [myMixin],

	data() {
		return {
			ruleForm: { clientName: '', pageSize: 10, pageNo: 1 },
			list: [],
			totalRows: 0,
			loading: false,
			replyVisible: false,
			editVisible: false,
			stateVisible: false,
			params: {
				businessType: 10,
			},
			stateList: [],
			isempty: true,
		};
	},
	methods: {
		async getSchemeApprovalInfoList(b = false) {
			const res = await schemeApprovalInfoList(this.ruleForm);
			if (res.returncode == 0) {
				if (b) {
					this.list = [];
				}
				setTimeout(() => {
					this.list = this.list.concat(res.list);
					this.totalRows = res.totalRows;
					this.isempty = this.list.length ? true : false;
				});
			}
		},
		load() {
			this.ruleForm.pageNo = this.ruleForm.pageNo + 1;
			this.loading = true;
			this.getSchemeApprovalInfoList();
			this.loading = false;
		},
		feedback(item) {
			this.params = item;
			this.params.businessType = 10;

			this.replyVisible = true;
		},
		editSuccess(obj) {
			let index = this.list.findIndex(
				(item) => item.schemeId === obj.schemeId
			);
			this.list.splice(index, 1, obj);
			this.editVisible = false;
		},
		editData(item, v) {
			this.params = item;
			this[v] = true;
		},
		async getSchemeStatus() {
			const res = await itemByType({
				dictionaryType: 'SchemeImplementCondition',
			});
			if (res.returncode === 0) {
				this.stateList = res.data;
			}
		},
		success(data) {
			let index = this.list.findIndex(
				(item) => item.schemeApprovalId == data.schemeApprovalId
			);

			this.$set(
				this.list[index],
				'currentApprovalLinkName',
				data.currentApprovalLinkName
			);
			this.$set(
				this.list[index],
				'currentApprovalLink',
				data.currentApprovalLink
			);
			this.$set(
				this.list[index],
				'nextApprovalLinkName',
				data.nextApprovalLinkName
			);
			this.$set(
				this.list[index],
				'nextApprovalLink',
				data.nextApprovalLink
			);
		},
	},
	created() {
		this.getSchemeStatus();
		this.getSchemeApprovalInfoList();
	},
	computed: {
		noMore() {
			return this.list.length >= this.totalRows;
		},
		disabled() {
			return this.loading || this.noMore;
		},
	},
	components: {
		myStateItem,
		editDrawer,
		stateChange,
	},
};
</script>
<style lang="scss" scoped>
div {
	box-sizing: border-box;
}

.approval {
	width: 100%;
	background: #fff;
	position: relative;
}

.content-title {
	font-size: 16px;
	font-weight: 800;
}

.content-box {
	display: flex;
	padding: 0 10px;

	div {
		flex: 1;
	}

	p {
		font-size: 14px;
		color: #888;
		margin: 20px 10px;
	}
}

.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}

.activeColor {
	background: #f64c4c;
}

.box-card {
	position: relative;
}
</style>
